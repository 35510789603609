export const environment = {
  production: false,

  //HOST:  'https://api.mifact.net/securityws',  
  // HOST:  'https://apisecuritydemo.mifact.pe/apisecuritydemo',
  HOST:  'https://securitybeta.mifact.pe/apisecurityws',
  //HOST: 'http://localhost:8080/securityws',
  TOKEN_AUTH_USERNAME: 'OsysCompany',
  TOKEN_AUTH_PASSWORD: 'Mifact2018',
  TOKEN_NAME: 'access_token',
  SESSION_NAME: 'access_session',
  ENVIOCORREO:"https://demo.mifact.net.pe/api/Security.svc/EnviarCorreoMifact",

  //api_url_java : 'http://localhost:8080/securityws/api/',
  //api_url_java : 'https://apisecuritydemo.mifact.pe/apisecuritydemo/api/',
  api_url_java : 'https://securitybeta.mifact.pe/apisecurityws/api/',

  // api_url_java : 'http://localhost:8082/api/',
  tokenWSSunat : 'OSYS20180821APP1',
  urlWsMiFactSUNAT:'https://mifact.net.pe/wsmifactsunat/ApiSUNAT.svc/ConsultaRUC'
};


