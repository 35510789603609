import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Proveedor } from 'src/app/models/proveedor';
import { MessageService } from 'primeng/api';
import { ProvedoresService } from 'src/app/services/provedores.service';
import { ApiMessageService } from 'src/app/services/api-message.service';
import { BlockUIModule } from 'primeng/blockui';
import { isNumber } from 'is-what';
import Swal from 'sweetalert2'
import { environment } from 'src/environments/environment';
import { ArchivoPrmConsultaRUC } from 'src/app/models/archivoPrmCosultaRUC';
import { ApiArchivoService } from 'src/app/services/api-archivo.service';
import { Contribuyente } from 'src/app/models/contribuyente';
import { ContribuyenteService } from 'src/app/services/contribuyente.service';



@Component({
  selector: 'app-mant-proveedor',
  templateUrl: './mant-proveedor.component.html',
  styleUrls: ['./mant-proveedor.component.css']
})
export class MantProveedorComponent implements OnInit {

  @ViewChild("myInput", { static: false })
  myInputVariable: ElementRef;

  ListadoProveedores: Proveedor[];
  arrGlobalFilters = ['nom_rzn_social_prov', 'num_ruc_prov'];

  flgDisplayPrincipal: boolean;
  flgnuevoProveedor: boolean;
  flgeditarProveedor: boolean;
  loading: boolean;
  objProveedorEdit: Proveedor = new Proveedor();
  objProveedorSel: Proveedor = new Proveedor();

  dataPrm: ArchivoPrmConsultaRUC = new ArchivoPrmConsultaRUC();
  private tokenWebService = environment.tokenWSSunat;
  activo: boolean;
  baja: boolean;
  correo: number;
  checked: boolean;
  estado: string;

  objContribuyenteEdit: Contribuyente = new Contribuyente();
  selectedFiles: FileList;
  currentFileUpload: File;
  url: any;

  constructor(
    private messageService: MessageService,
    private serviceproveedor: ProvedoresService,
    private msgCatalog: ApiMessageService,
    public blokeo: BlockUIModule,
    private archivosService: ApiArchivoService,
    private serviceContribuyente: ContribuyenteService,
  ) { }

  ngOnInit() {

    this.flgDisplayPrincipal = true;
    this.blokeo = false;
    this.loading = true;
    this.getProveedorAll();

  }
  getProveedorAll() {
    this.serviceproveedor.getAll().subscribe(data => {
      this.ListadoProveedores = data;
      this.loading = false;
    })
  }


  bntnuevo() {
    this.flgDisplayPrincipal = false
    this.flgnuevoProveedor = true
    this.checked = true
    this.estado = "ACTIVO"
    this.url =""
  }

  btncancelar() {
    this.flgDisplayPrincipal = true
    this.flgnuevoProveedor = false
    this.flgeditarProveedor = false
    this.objProveedorEdit = new Proveedor();

  }

  click() {
    if (this.activo != true) {
      this.objProveedorEdit.flg_estado = 0;
    } else {
      this.objProveedorEdit.flg_estado = 1;
      this.baja = false;
    }
  }

  click1() {
    if (this.baja != true) {
      this.objProveedorEdit.flg_estado = 1;

    } else {
      this.objProveedorEdit.flg_estado = 0;
      this.activo = false;
    }
  }


  GuardarProvedor() {
    this.blokeo = true;

    if (this.objProveedorEdit.num_ruc_prov == null) {
      this.messageService.add({ severity: 'error', summary: 'RUC Proveedor', detail: 'Debe Ingresar RUC Proveedor' });
      this.blokeo = false;
      return false;
    }


    if (this.objProveedorEdit.num_ruc_prov.length < 11) {
      this.messageService.add({ severity: 'error', summary: 'RUC Proveedor', detail: 'Debe Ingresar 11 caracteres' });
      this.blokeo = false;
      return false;
    }

    if (this.objProveedorEdit.nom_rzn_social_prov == null || this.objProveedorEdit.nom_rzn_social_prov.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Nombre o Razon Social del Proveedor', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    if (this.objProveedorEdit.txt_dmcl_fisc_prov == null || this.objProveedorEdit.txt_dmcl_fisc_prov.length < 2) {
      this.messageService.add({ severity: 'error', summary: 'Dirección', detail: 'Debe Ingresar mínimo 2 caracteres' });
      this.blokeo = false;
      return false;
    }

    if (this.objProveedorEdit.txt_mail_prov != null) {
      this.blokeo = true;
      this.validarEmail()
      if (this.correo == 1) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La dirección de email ' + this.objProveedorEdit.txt_mail_prov + ' es incorrecta.' });
        this.blokeo = false;
        return false;
      }
    }


    this.objProveedorEdit.nom_rzn_social_prov = this.objProveedorEdit.nom_rzn_social_prov.trim();
    this.objProveedorEdit.txt_dmcl_fisc_prov = this.objProveedorEdit.txt_dmcl_fisc_prov.trim();


    if (this.checked == true) {
      this.objProveedorEdit.flg_estado = 1;
    } else {
      this.objProveedorEdit.flg_estado = 0;
    }


    if (isNumber(this.objProveedorEdit.id_proveedores)) {
      this.serviceproveedor.update(this.objProveedorEdit).subscribe(res => {
        this.getProveedorAll();
        this.activo = false;
        this.baja = false;

        this.objProveedorEdit = new Proveedor();
        this.flgeditarProveedor = false;
        this.flgDisplayPrincipal = true;
        this.blokeo = false;
        Swal.fire({
          type: 'success',
          title: 'El Proveedor se Actualizo Correctamente',
          confirmButtonText: 'Aceptar'
        })
      });
    } else {


      this.serviceproveedor.getallProveedor(this.objProveedorEdit.num_ruc_prov).subscribe(items => {
        let flg = (items.length > 1 ? true : false);
        if (items.length == 0) {

          this.serviceproveedor.save(this.objProveedorEdit).subscribe(res => {
            this.getProveedorAll();
            this.activo = false;
            this.baja = false;
            this.objProveedorEdit = new Proveedor();
            this.flgnuevoProveedor = false;
            this.flgDisplayPrincipal = true;
            this.blokeo = false;
            Swal.fire({
              type: 'success',
              title: 'El Proveedor se Registro Correctamente',
              confirmButtonText: 'Aceptar'
            })
          });
        }
        else {
          this.blokeo = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Proveedor ya existe ' });
        }
      });

    }
  }

  getProveedorId() {
    this.serviceproveedor.getById(this.objProveedorSel.id_proveedores).subscribe(
      data => {
        this.objProveedorEdit = { ...data };
        this.blokeo = false
        this.url = "https://img-logos-mifact.s3.amazonaws.com/"+this.objProveedorEdit.num_ruc_prov+"/logo_"+this.objProveedorEdit.num_ruc_prov+".png"+"?t=" + new Date().getTime();
      });
  }

  editarProveedor(proveedor: Proveedor) {
    this.blokeo = true;
    this.objProveedorSel = proveedor;
    this.getProveedorId();
    this.flgDisplayPrincipal = false;
    this.flgeditarProveedor = true;
    if (this.objProveedorSel.flg_estado != 0) {
      this.checked = true;
    } else if (this.objProveedorSel.flg_estado == 0) {
      this.checked = false;
    } else {
    }
  }

  click_consultaRUC() {

    if (this.objProveedorEdit.num_ruc_prov == null) {
      this.messageService.add({ severity: 'error', summary: 'RUC Proveedor', detail: 'Debe Ingresar RUC Proveedor' });
      this.blokeo = false;
      return false;
    }


    if (this.objProveedorEdit.num_ruc_prov.length < 11) {
      this.messageService.add({ severity: 'error', summary: 'RUC Proveedor', detail: 'Debe Ingresar 11 caracteres' });
      this.blokeo = false;
      return false;
    }

    this.blokeo = true;
    this.dataPrm.TOKEN = this.tokenWebService;
    this.dataPrm.RUC_RECEPTOR = this.objProveedorEdit.num_ruc_prov;


    this.archivosService.getConsultaRUC(this.dataPrm).subscribe(data => {

      if (data.aMensajeAPP != "OK") {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener la data: ' + data.aCod_MensajeAPP });
        this.blokeo = false;
        return false;
      }
      if (data.aMensajeAPP == "OK") {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Contribuyente encontrado. ' });
        this.objProveedorEdit.nom_rzn_social_prov = data.aRazon_Social;
        this.objProveedorEdit.txt_dmcl_fisc_prov = data.aDireccion_Fiscal;
        this.blokeo = false;
        if (data.aCondicion_Contr != 'HABIDO') {
          this.messageService.add({ severity: 'warning', summary: 'Observación', detail: 'Cliente . ' + data.aCondicion_Contr });
          this.blokeo = false;
        }
        if (data.aEstado_Contr != 'ACTIVO') {
          this.messageService.add({ severity: 'warning', summary: 'Observación', detail: 'Cliente . ' + data.aEstado_Contr });
          this.blokeo = false;
        }
        this.blokeo = false;
        return true;
      }


    });

  }


  validarEmail() {

    if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.objProveedorEdit.txt_mail_prov)) {
      this.blokeo = true;
      this.correo = 0;
    } else {
      this.blokeo = true;
      this.correo = 1;
    }
  }


  check() {
    if (this.checked == true) {
      this.estado = "ACTIVO"
    } else {
      this.estado = "BAJA"
    }

  }

  selectFile(event) {


    this.blokeo = true;
    this.selectedFiles = event.target.files;
    this.currentFileUpload = this.selectedFiles.item(0);
    if (this.currentFileUpload.type == "image/png") {
      if (this.selectedFiles && this.currentFileUpload) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.url = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
        var reader1 = new FileReader();
        reader1.onload = this._handleReaderLoaded.bind(this);
        reader1.readAsBinaryString(this.selectedFiles.item(0));
      }
    } else {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Solo Archivos PNG",
      });
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.objContribuyenteEdit.imagenS3 = btoa(binaryString);
    this.subirImagen();
  }

  subirImagen() {
    var empresa = new Contribuyente();

    empresa.num_nif_emis = this.objContribuyenteEdit.num_nif_emis;
    empresa.tipoImagenS3 = ".png";
    empresa.ls_url_images_s3_accessKey = "AKIA3XFMJH6G6IU3UOMG";
    empresa.ls_url_images_s3_secretKey = "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";
    empresa.imagenS3 = this.objContribuyenteEdit.imagenS3;
    empresa.num_nif_emis = this.objProveedorEdit.num_ruc_prov
    this.serviceContribuyente.UploadImage(empresa).subscribe((data) => {
      this.blokeo = false;
      this.messageService.add({
        severity: "success",
        summary: "Exito",
        detail: data.num_nif_emis,
      });
      this.myInputVariable.nativeElement.value = "";
    });
  }

}
